body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  background-color: #050330 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .circle {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 0;
  left: 0;
  opacity: 0.3;
} */

.circle.sm {
  width: 20px;
  height: 20px;
}

.circle.md {
  width: 35px;
  height: 35px;
}

.circle.lg {
  width: 50px;
  height: 50px;
}

/* .details-box {
  position: relative;
  overflow: hidden;
}
.details-box .span {
  position: absolute;
  display: block;
}

.details-box .span:nth-child(1) {
  content: "";
  top: 0px;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    113.1deg,
    #2be233 3.48%,
    #ff772a 38.5%,
    #ff3566 60.53%,
    #ff0095 78%
  );
  animation: btn-anim1 3s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.details-box .span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(
    113.1deg,
    #2be233 3.48%,
    #ff772a 38.5%,
    #ff3566 60.53%,
    #ff0095 78%
  );
  animation: btn-anim2 3s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.details-box .span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    113.1deg,
    #2be233 3.48%,
    #ff772a 38.5%,
    #ff3566 60.53%,
    #ff0095 78%
  );
  animation: btn-anim3 3s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.details-box .span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(
    113.1deg,
    #2be233 3.48%,
    #ff772a 38.5%,
    #ff3566 60.53%,
    #ff0095 78%
  );
  animation: btn-anim4 3s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
} */

@media screen and (max-width: 544px) {
  .MuiDialog-paperScrollPaper {
    width: 100%;
  }
}

/* .teamChildDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.teamParentDiv {
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.teamParentDiv:hover + .teamChildDiv {
  display: none;
} */