.App {
  text-align: center;
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  overflow-x: hidden !important;
  background-color: #050330 !important;
}

/* svg {
  margin-top: 50px;
} */
svg #letters path {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  fill-opacity: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}